import React from "react";
import { Field, FieldProps } from "formik";
import { Select } from "@chakra-ui/react";

export function MatchEndSelectField({ name, placeholder }: { name: string; placeholder?: string }) {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <Select name={field.name} onChange={field.onChange} value={field.value ?? undefined} placeholder={placeholder}>
          <option value="NORMAL_TIME">Normaalaeg</option>
          <option value="EXTRA_TIME">Lisaaeg</option>
          <option value="PENALTY_SHOOTOUT">Penaltid</option>
        </Select>
      )}
    </Field>
  );
}
