import React from "react";
import { Field, FieldProps } from "formik";
import { Select } from "@chakra-ui/react";

export function ScoreSelectField({ name, placeholder }: { name: string; placeholder?: string }) {
  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <Select name={field.name} onChange={field.onChange} value={field.value ?? undefined} placeholder={placeholder}>
          {options.map((value) => (
            <option key={value}>{value}</option>
          ))}
        </Select>
      )}
    </Field>
  );
}
