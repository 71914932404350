import useSWR, { useSWRConfig } from "swr";
import type { GetMatchesResponse, MatchWithMyPrediction, PastMatch } from "../api/matches";
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Heading,
  SimpleGrid,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { formatDateTime } from "../../lib/date-util";
import { Form, Formik, FormikHandlers } from "formik";
import React from "react";
import axios from "axios";
import Head from "next/head";
import { Spinner } from "../../components/Spinner";
import { Alert, DataLoadingError } from "../../components/Error";
import { PointsBadge } from "../../components/PointsBadge";
import { ScoreSelectField } from "../../components/ScoreSelectField";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import NextLink from "next/link";
import { MatchEndSelectField } from "components/MatchEndSelectField";
import { ScoreAndMatchEndType } from "components/ScoreAndMatchEndType";
import { MatchEndType } from "lib/types";
import { translateCountryWithFlagLeft, translateCountryWithFlagRight } from "../../lib/translate";
import { FormikState } from "formik/dist/types";
import { Checkmark } from "../../components/Checkmark";
import { getRoundTranslation } from "../../lib/round-translations";

export default function MatchesPage() {
  const { data, error } = useSWR<GetMatchesResponse>("/api/matches");

  if (error) return <DataLoadingError error={error} />;
  if (!data) return <Spinner />;

  return (
    <>
      <Head>
        <title>Euro 2024 - Mängud</title>
      </Head>
      <Container>
        <LiveMatches matches={data.ongoing} />
        <UpcomingMatches matches={data.upcoming} />
        {data.ongoing.length === 0 && data.upcoming.length === 0 ? <Alert message="Mängud on lõppenud" status="info" /> : null}
      </Container>
    </>
  );
}

function LiveMatches({ matches }: { matches: MatchWithMyPrediction[] }) {
  if (!matches?.length) {
    return null;
  }

  return (
    <>
      <Heading size="md">Praegused mängud</Heading>
      {matches.map((m) => (
        <Box key={m.matchId} rounded={4} borderWidth={2} p={2} my={2} textAlign="center">
          <MatchInfo match={m} showScore={true} />
          <Divider my={2} />
          <VStack>
            <PointsBadge points={m.points!} suffix={true} />
            <PredictionBadge match={m} />
          </VStack>
        </Box>
      ))}
    </>
  );
}

function UpcomingMatches({ matches }: { matches: MatchWithMyPrediction[] }) {
  if (!matches.length) {
    return null;
  }

  return (
    <>
      <Heading size="md">Järgmised mängud</Heading>
      {matches.map((m, index) => {
        // Shown if match type is different from previous
        let sectionHeader = m.matchType === matches[index - 1]?.matchType ? undefined : m.matchType;

        return (
          <Box key={m.matchId}>
            {sectionHeader && (
              <Heading mt={4} size="sm">
                {getRoundTranslation(sectionHeader)}
              </Heading>
            )}
            <Box rounded={10} borderWidth={3} p={2} my={2} textAlign="center">
              <MatchInfo match={m} showScore={false} showPredictionCheckmark={true} />
              <Divider my={2} />
              <Box>
                <PredictionForm match={m} />
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}

function MatchInfo({ match, showScore, showPredictionCheckmark }: { match: MatchWithMyPrediction; showScore: boolean; showPredictionCheckmark?: boolean }) {
  const hasPrediction = match.predictionCount > 0;

  return (
    <>
      <SimpleGrid columns={3}>
        <Flex justifyContent="flex-start">
          {showPredictionCheckmark ? (
            <Tooltip label={hasPrediction ? "Ennustus on tehtud" : "Ennustus on tegemata"}>
              <Box>
                <Checkmark checked={hasPrediction}></Checkmark>
              </Box>
            </Tooltip>
          ) : null}
        </Flex>
        <Flex fontSize="x-large">
          <Flex flex={1} justifyContent="flex-end">
            {match.team1Code}
          </Flex>
          <Box>&nbsp;-&nbsp;</Box>
          <Flex flex={1} justifyContent="flex-start">
            {match.team2Code}
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <NextLink href={`matches/${match.matchId}`} passHref legacyBehavior={true}>
            <Button colorScheme="gray" size="xs" rightIcon={<ArrowForwardIcon />}>
              Ennustused
            </Button>
          </NextLink>
        </Flex>
      </SimpleGrid>
      <Flex color="gray.500">
        <Flex flex={1} justifyContent="flex-end">
          {translateCountryWithFlagRight(match.team1Code)}
        </Flex>
        <Box>&nbsp;-&nbsp;</Box>
        <Flex flex={1} justifyContent="flex-start">
          {translateCountryWithFlagLeft(match.team2Code)}
        </Flex>
      </Flex>
      <Box fontSize="sm">{formatDateTime(match.dateTime)}</Box>
      <Flex color="gray.500" fontSize="xx-small" gap={3} py={2}>
        <PastMatchesBlock matches={match.team1PastMatches} teamCode={match.team1Code} justifyContent="flex-end" />
        <PastMatchesBlock matches={match.team2PastMatches} teamCode={match.team2Code} justifyContent="flex-start" />
      </Flex>
      <Box>
        {showScore ? (
          <Badge variant="solid" fontSize="md" className="blink">
            <ScoreAndMatchEndType
              team1Score={match.team1Score}
              team2Score={match.team2Score}
              matchEndType={match.isKnockoutStage ? match.matchEndType : null}
            />
          </Badge>
        ) : null}
      </Box>
    </>
  );
}

function PredictionForm({ match }: { match: MatchWithMyPrediction }) {
  const { mutate } = useSWRConfig();
  const toast = useToast();

  type FormData = {
    team1Score: string | number | null;
    team2Score: string | number | null;
    matchEndType: MatchEndType | null;
  };

  const handleSubmit = async (v: FormData) => {
    try {
      await axios.post(`/api/matches/${match.matchId}/predictions`, {
        team1Score: Number(v.team1Score),
        team2Score: Number(v.team2Score),
        matchEndType: v.matchEndType ?? undefined,
      });
      await mutate("/api/matches");
    } catch (e) {
      toast({
        status: "error",
        description: "Tekkis viga, valikut ei salvestatud",
        position: "top",
        isClosable: true,
      });
    }
  };

  const hasTeam1Score = (match.predictedTeam1Score ?? -1) >= 0;
  const hasTeam2Score = (match.predictedTeam1Score ?? -1) >= 0;
  const hasMatchEndType = !!match.predictedMatchEndType;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        team1Score: match.predictedTeam1Score,
        team2Score: match.predictedTeam2Score,
        matchEndType: match.predictedMatchEndType,
      }}
      validateOnChange={false}
      validate={(values: any) => {
        const isValid = (v: string | number | null) => v !== "" && v !== null;

        const errors: any = {};

        if (!isValid(values.team1Score)) {
          errors.team1Score = true;
        }
        if (!isValid(values.team2Score)) {
          errors.team2Score = true;
        }
        if (match.isKnockoutStage && !values.matchEndType) {
          errors.matchEndType = true;
        }
        if (
          values.matchEndType === "PENALTY_SHOOTOUT" &&
          isValid(values.team1Score) &&
          isValid(values.team2Score) &&
          Number(values.team1Score) !== Number(values.team2Score)
        ) {
          errors.matchEndTypePenalty = true;
        }
        if (
          (values.matchEndType === "NORMAL_TIME" || values.matchEndType === "EXTRA_TIME") &&
          isValid(values.team1Score) &&
          isValid(values.team2Score) &&
          Number(values.team1Score) === Number(values.team2Score)
        ) {
          errors.matchEndTypeNotPenalty = true;
        }

        return errors;
      }}
      onSubmit={handleSubmit}
    >
      {(props: FormikHandlers & FormikState<any>) => (
        <Form onChange={() => setTimeout(props.handleSubmit)}>
          <Flex>
            <Box flex={1} mr={2}>
              <ScoreSelectField name="team1Score" placeholder={hasTeam1Score ? undefined : "Vali"} />
            </Box>
            <Box flex={1}>
              <ScoreSelectField name="team2Score" placeholder={hasTeam2Score ? undefined : "Vali"} />
            </Box>
            {match.isKnockoutStage ? (
              <Box flex={2} ml={2}>
                <FormControl isInvalid={!!props.errors.matchEndTypePenalty || !!props.errors.matchEndTypeNotPenalty}>
                  <MatchEndSelectField name="matchEndType" placeholder={hasMatchEndType ? undefined : "Vali"} />
                  {props.errors.matchEndTypeNotPenalty ? <FormErrorMessage>Viigi korral pead valima penaltid</FormErrorMessage> : null}
                  {props.errors.matchEndTypePenalty ? <FormErrorMessage>Penaltite valimiseks pead ennustama viiki</FormErrorMessage> : null}
                </FormControl>
              </Box>
            ) : null}
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

function PredictionBadge({ match }: { match: MatchWithMyPrediction }) {
  if (match.predictionCount > 0) {
    return (
      <Badge variant="outline" fontSize="md">
        <ScoreAndMatchEndType team1Score={match.predictedTeam1Score!} team2Score={match.predictedTeam2Score!} matchEndType={match.predictedMatchEndType} />
      </Badge>
    );
  }
  return (
    <Badge colorScheme="red" variant="outline">
      Ennustus puudub
    </Badge>
  );
}

function PastMatchesBlock({ matches, teamCode, justifyContent }: { matches: PastMatch[]; teamCode: string; justifyContent: "flex-end" | "flex-start" }) {
  return (
    <Flex flex={1} justifyContent={justifyContent}>
      <Box>
        {matches.map((pm) => (
          <Grid gridTemplateColumns="repeat(3, 1fr)" key={pm.id} alignItems="center" my={1}>
            <Box>{pm.team1Code}</Box>
            <Badge fontSize="x-small" variant="solid" colorScheme={teamCode === pm.winnerTeam ? "green" : pm.winnerTeam ? "red" : undefined}>
              <ScoreAndMatchEndType team1Score={pm.team1Score} team2Score={pm.team2Score} matchEndType={pm.isKnockoutStage ? pm.matchEndType : null} />
            </Badge>
            <Box>{pm.team2Code}</Box>
          </Grid>
        ))}
      </Box>
    </Flex>
  );
}
