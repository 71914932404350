import styles from "./Checkmark.module.css";
import classNames from "classnames";
import { useState } from "react";

// Animated checkmark based on example from https://stackoverflow.com/a/41078668
export function Checkmark({ checked }: { checked: boolean }) {
  const [isChecked] = useState(checked);
  const animated = checked != isChecked; // Animate only when checked state changes
  const checkmarkClass = classNames({
    [styles.checkmark]: true,
    [styles.checkmark_animated]: animated,
    [styles.ok]: checked,
    [styles.nok]: !checked,
  });

  const checkmarkCircleClass = classNames({
    [styles.checkmark__circle]: true,
    [styles.checkmark__circle_animated]: animated,
  });

  const checkmarkCheckClass = classNames({
    [styles.checkmark__check]: true,
    [styles.checkmark__check_animated]: animated,
  });

  return (
    <svg className={checkmarkClass} viewBox="0 0 52 52">
      <circle className={checkmarkCircleClass} cx="26" cy="26" r="25" fill="none" />
      {checked ? (
        <path className={checkmarkCheckClass} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      ) : (
        <path className={checkmarkCheckClass} fill="none" d="M16 16 36 36 M36 16 16 36" />
      )}
    </svg>
  );
}
