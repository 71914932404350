import { MatchEndType } from "../lib/types";

export function ScoreAndMatchEndType({ team1Score, team2Score, matchEndType }: { team1Score: number; team2Score: number; matchEndType: MatchEndType | null }) {
  return (
    <>
      {team1Score}-{team2Score} {matchEndTypeShortLabel(matchEndType)}
    </>
  );
}

function matchEndTypeShortLabel(matchEndType: MatchEndType | null) {
  switch (matchEndType) {
    case "NORMAL_TIME":
      return "N";
    case "EXTRA_TIME":
      return "L";
    case "PENALTY_SHOOTOUT":
      return "P";
    default:
      return null;
  }
}
