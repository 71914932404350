// TODO move elsewhere?
export const countries: Record<string, string> = {
  ALB: "Albaania",
  AUT: "Austria",
  BEL: "Belgia",
  CRO: "Horvaatia",
  CZE: "Tšehhi",
  DEN: "Taani",
  ENG: "Inglismaa",
  ESP: "Hispaania",
  FRA: "Prantsusmaa",
  GEO: "Gruusia",
  GER: "Saksamaa",
  HUN: "Ungari",
  ITA: "Itaalia",
  NED: "Holland",
  POL: "Poola",
  POR: "Portugal",
  ROU: "Rumeenia",
  SCO: "Šotimaa",
  SLO: "Sloveenia",
  SRB: "Serbia",
  SUI: "Šveits",
  SVK: "Slovakkia",
  TUR: "Türgi",
  UKR: "Ukraina",
  "?": "Teadmata",
};
const flags: Record<string, string> = {
  ALB: "🇦🇱",
  AUT: "🇦🇹",
  BEL: "🇧🇪",
  CRO: "🇭🇷",
  CZE: "🇨🇿",
  DEN: "🇩🇰",
  ENG: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
  ESP: "🇪🇸",
  FRA: "🇫🇷",
  GEO: "🇬🇪",
  GER: "🇩🇪",
  HUN: "🇭🇺",
  ITA: "🇮🇹",
  NED: "🇳🇱",
  POL: "🇵🇱",
  POR: "🇵🇹",
  ROU: "🇷🇴",
  SCO: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
  SLO: "🇸🇮",
  SRB: "🇷🇸",
  SUI: "🇨🇭",
  SVK: "🇸🇰",
  TUR: "🇹🇷",
  UKR: "🇺🇦",
  "?": "🏳",
};


export function translateCountryWithFlagRight(countryName: string): string {
  return translateCountryWithFlag(countryName, 'right')
}

export function translateCountryWithFlagLeft(countryName: string): string {
  return translateCountryWithFlag(countryName, 'left')
}

function translateCountryWithFlag(countryName: string, position: 'left' | 'right') {
  const translatedCountry = countries[countryName];

  if (navigator.userAgent.includes('Windows')) {
    return translatedCountry; // Windows does not support emoji flags, so don't display them
  }

  const flag = flags[countryName];
  return (`${position === 'left' ? flag : ''} ${translatedCountry} ${position === 'right' ? flag : ''}`).trim();
}