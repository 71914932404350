import { MatchType } from "./types";

export function getRoundTranslation(matchType: MatchType) {
  if (matchType === 'MATCHDAY_1') return "Alagrupimängude 1. voor";
  if (matchType === 'MATCHDAY_2') return "Alagrupimängude 2. voor";
  if (matchType === 'MATCHDAY_3') return "Alagrupimängude 3. voor";
  if (matchType === 'ROUND_OF_16') return "Kaheksandikfinaalid";
  if (matchType === 'QUARTER_FINAL') return "Veerandfinaalid";
  if (matchType === 'SEMI_FINAL') return "Poolfinaalid";
  if (matchType === 'FINAL') return "Finaal";
  return undefined;
}