import { Alert as ChakraAlert, AlertIcon, AlertStatus, Container } from "@chakra-ui/react";
import React from "react";

export function DataLoadingError({ error }: { error: any }) {
  if (error?.response?.data.code === 'unconfirmed') {
    return <Alert message="Oota kuni Raido su konto kinnitab (täna, homme või ülehomme)" status="warning" />;
  }

  return <Alert message="Andmete laadimine ebaõnnestus" />;
}

export function Alert({ message, status = "error" }: { message: string; status?: AlertStatus }) {
  return (
    <Container>
      <ChakraAlert status={status} rounded={10}>
        <AlertIcon />
        {message}
      </ChakraAlert>
    </Container>
  );
}
