import { format, formatRelative } from "date-fns";
import { et } from "date-fns/locale";

export function formatDateTime(dateTime: Date | string | null) {
  if (!dateTime) return null;
  return format(new Date(dateTime), "EEEEE, dd.MM HH:mm", {
    locale: et,
  });
}

export function formatRelativeTime(dateTime: Date | string | null) {
  if (!dateTime) return null;
  return formatRelative(new Date(dateTime), new Date(), {
    locale: et,
  });
}

export function formatDate(dateTime: Date | string | null) {
  if (!dateTime) return null;
  return format(new Date(dateTime), "dd.MM", {
    locale: et,
  });
}
